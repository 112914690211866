const bufferToWav = require('audiobuffer-to-wav');

const getRGB = (c) => {
    return parseInt(c, 16) || c;
};

const getsRGB = (c) => {
    return getRGB(c) / 255 <= 0.03928
        ? getRGB(c) / 255 / 12.92
        : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4);
};

const getLuminance = (hexColor) => {
    return (
        0.2126 * getsRGB(hexColor.substr(1, 2)) +
        0.7152 * getsRGB(hexColor.substr(3, 2)) +
        0.0722 * getsRGB(hexColor.substr(-2))
    );
};

const getContrast = (f, b) => {
    const L1 = getLuminance(f);
    const L2 = getLuminance(b);
    return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
};

const getTextColor = (bgColor) => {
    const whiteContrast = getContrast(bgColor, '#ffffff');
    const blackContrast = getContrast(bgColor, '#000000');

    return whiteContrast > blackContrast ? '#ffffff' : '#000000';
};

const exportWAV = (buffer) => {
    const wav = bufferToWav(buffer);

    return new Blob([new DataView(wav)], { type: 'audio/wav' });
};

const dragElement = (element, dragzone) => {
    let pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;

    //MouseUp occurs when the user releases the mouse button
    const dragMouseUp = () => {
        document.onmouseup = null;
        //onmousemove attribute fires when the pointer is moving while it is over an element.
        document.onmousemove = null;

        element.classList.remove('drag');
    };

    const dragMouseMove = (event) => {
        event.preventDefault();
        //clientX property returns the horizontal coordinate of the mouse pointer
        pos1 = pos3 - event.clientX;
        //clientY property returns the vertical coordinate of the mouse pointer
        pos2 = pos4 - event.clientY;
        pos3 = event.clientX;
        pos4 = event.clientY;
        //offsetTop property returns the top position relative to the parent
        element.style.top = `${element.offsetTop - pos2}px`;
        element.style.left = `${element.offsetLeft - pos1}px`;
    };

    const dragMouseDown = (event) => {
        event.preventDefault();

        pos3 = event.clientX;
        pos4 = event.clientY;

        element.classList.add('drag');

        document.onmouseup = dragMouseUp;
        document.onmousemove = dragMouseMove;
    };

    dragzone.onmousedown = dragMouseDown;
};

module.exports = {
    getTextColor,
    exportWAV,
    dragElement,
};
